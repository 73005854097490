module.exports = {
    seo: {
        title : "Jenko - Article - Prototype Development",
        description:  ""
    },
    intro: {
        title: "Prototype Development"
    },
    articleHeader: {
        desc: "You have a brilliant idea for a product that just needs to be released… You have done a comprehensive characterization and performed a thorough feasibility study. You’ve got a targeted snapshot of your audience, market, potential profits and production and development costs. You are ready to build\u00A0the\u00A0prototype!"
    },
    secP: {
        title: "Prototype development",
        p: [
            'It’s not complicated to define the fairly common way of going from a brilliant idea in the mind to an active startup that generates actual profits. Although there are minor differences between industries, there is a process with clear guidelines that outlines the path for each entrepreneur, regardless of the nature or purpose of the product. One of the most significant questions that will determine whether the search for an investor bears fruit is the timing\u00A0question.',
            'Searching for an investor is an individual process and as such, it has no rules. You can look for an investor at the idea stage, or when a prototype and business plan already exist, or when the business is already active and operational. The timing question has a big impact on the investor recruitment process. The rule of thumb is that when the hunt for an investor takes place at a more advanced stage of the product, or the more developed the product, the more likely it is to find investors and to raise capital. And that makes sense. Anyone would feel more comfortable putting their eggs in a basket that has actual receipts that indicate safety and viability than in a basket whose viability is questionable and it may very well be that the idea behind it turns out to be a foolishly\u00A0accepted\u00A0one.',
            'A common mistake that many entrepreneurs tend to make, for example, is recruiting early on, in exchange for too much of the company. This can make it difficult for future rounds of funding when other investors see how small the founder’s share of the company is and would prefer to continue with startups where the founder has greater control. As with everything in life, no matter at what stage the capital raising takes place, there will be pros and cons. Either way, in order to succeed in recruiting a serious investor you need to have a clear vision, clear business plan, prototype or a development team on the way to a prototype and manager or management team who knows how to navigate\u00A0the\u00A0ship.'
        ]
    },
    thrdP: {
        title: "How to build\u00A0a\u00A0prototype?",
        p: [
            'There are several stages in prototype development.',
            'In the initial stage, requirements are defined. The product inventor defines specifications of customer requirements while the experts in their various fields produce characterizations based on the requirements and findings. The engineers in various fields consolidate engineering requirements, designers take care of the design requirements and specific engineering talent guide the various professionals in the project on how to integrate between all the fields and turn the characterization into a product, or in other words, how to bring all this\u00A0into\u00A0practice!',
            'The design requirements will accompany the product later on in the mass production phase. This is how an accurate roadmap is created to pave the way to prototype production. The prototype can be referred to as the “ancestor” of the final product to be sold in the advanced versions. At this point you can check the possibility of registering a patent. It should be noted that this is not always possible and, if possible, you will have to decide whether to bear the\u00A0costs\u00A0involved.'
        ]
    },
    frthP: {
        title: "Prototype production\u00A0cost",
        p: "The production costs of the different prototypes vary according to the type of product. It can be software, hardware, home, industrial, medical, engineering, mobile, electrical or wireless based and the list goes on. Some companies are prepared to manufacture prototypes within the company from A to Z, while others work with partners who produce the product as a whole or parts of it. It is worth choosing a company that owns advanced technological equipment and especially dedicated 3D printers that significantly save costs in the preparation phase for mass production. These printers are ready for advanced industrial printing and with them you get a product that is identical in size and appearance to the desired final product. The use of 3D printing allows you to focus on product design considering the ability to make repeated attempts until the perfect product is obtained. This effectively avoids the need to invest huge sums in prototyping the actual materials selected for\u00A0mass\u00A0production."
    },
    dir: 'ltr'
};