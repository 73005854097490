import React from "react";
import Helmet from "react-helmet";

const PageHead = (props) => {
  const { title, description } = props.pageHeadData;
  return (
    <Helmet
      title={title}
      meta={[{ name: "description", content: { description } }]}
    />
  );
};

export default PageHead;
