// extracted by mini-css-extract-plugin
export var sArticleContainer = "index-module--sArticleContainer--XdcU9";
export var sFI1Sec = "index-module--sFI1Sec--O9n-0";
export var sFI2Sec = "index-module--sFI2Sec--aA3e9";
export var sFI2SecWrap = "index-module--sFI2SecWrap--KUHG+";
export var sFI3Sec = "index-module--sFI3Sec--4qa5L";
export var sFI4Sec = "index-module--sFI4Sec--S-99p";
export var sFI5Sec = "index-module--sFI5Sec--jJljP";
export var sFI6Sec = "index-module--sFI6Sec--FIIz7";
export var sIP1Sec = "index-module--sIP1Sec--yJP+a";
export var sIP2Sec = "index-module--sIP2Sec--hO4AA";
export var sIP3Sec = "index-module--sIP3Sec--Afh46";
export var sIP4Sec = "index-module--sIP4Sec--GwM1+";
export var sIP5Sec = "index-module--sIP5Sec--kV3pZ";
export var sIP6Sec = "index-module--sIP6Sec--dNO++";
export var sIP7Sec = "index-module--sIP7Sec--2BGJi";
export var sIntro = "index-module--sIntro--1JGAI";
export var sIntroDesc = "index-module--sIntroDesc--dbXJG";
export var sIntroSection = "index-module--sIntroSection--JRRww";
export var sMP1sec = "index-module--sMP1sec--w2tIk";
export var sMP2sec = "index-module--sMP2sec--fB-XQ";
export var sMP3sec = "index-module--sMP3sec--nYzp5";
export var sMP4sec = "index-module--sMP4sec--ALqnn";
export var sMP5sec = "index-module--sMP5sec--ivvlc";
export var sMP5secIn = "index-module--sMP5secIn--vCrFE";
export var sPD1Sec = "index-module--sPD1Sec--HUeaI";
export var sPD2Sec = "index-module--sPD2Sec--yDoih";
export var sPD2SecH2 = "index-module--sPD2SecH2--MrUui";
export var sPD2SecSplit = "index-module--sPD2SecSplit--AUw2c";
export var sPD3Sec = "index-module--sPD3Sec--aJPBb";
export var sPD4Sec = "index-module--sPD4Sec--FVz-+";
export var sPR1Sec = "index-module--sPR1Sec--PsRI2";
export var sPR2Sec = "index-module--sPR2Sec--JEEMc";
export var sPR3Sec = "index-module--sPR3Sec--goCH-";
export var sPR4Sec = "index-module--sPR4Sec--z5Gi0";
export var sPR5Sec = "index-module--sPR5Sec--bPano";
export var sPR6Sec = "index-module--sPR6Sec--ocYXG";