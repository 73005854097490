import React, { useContext } from "react";
import { Context } from "../../context/Context";

const Intro = (props) => {
  const { content, styles } = props;
  const context = useContext(Context);

  return (
    <section className={styles.sIntroSection}>
      <div className={styles.sIntro}>
        <h1>{content.title}</h1>
        <div className={styles.sIntroDesc}>
          {content.desc ? <h2>{content.desc}</h2> : null}
          {content.addDesc ? <p>{content.addDesc}</p> : null}
        </div>
        {content.cta ? (
          <button onClick={context.openModal()}>{content.cta} </button>
        ) : null}
      </div>
    </section>
  );
};

export default Intro;
